.storedLeft
{
  margin-bottom: 10px;
}

.storedLeft
{
  float: left;
}

.storedRight
{
  float: right;
}