@media only screen and (max-width: 540px) {
    .receipt table th, .receipt table td {
        padding: 5px;
    }
    .receipt table {
        overflow-y: scroll;
        border-collapse: separate;
        word-break: break-word;
    }
    .receipt table{
        border: 1px solid gainsboro;
    }
    .receipt table tr>td:nth-child(2) {
        text-align: left;
    }
    .receipt table > thead > tr > th{
        /* border:1px solid gainsboro; */
    }
    .receipt table > tbody > tr > td{
        /* border:1px solid gainsboro; */
    }
}
