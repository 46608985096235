.CompanyInfo { /* CSS DEBUG */
    /* border: 1px solid purple; */
}
.CompanyInfo {
    text-align: center;
    width: 100%;
}

.CompanyInfo .clearButton {
    border: 1px solid gainsboro;
    width: 200px;
    display: inline-block;
    margin: 5px 0px 5px 0px;
}

.CompanyInfo .clickable
{
  cursor: pointer
}