.DropDown {
    /* width:300px; */
    overflow:hidden;
    /* border: 1px solid red; */
    text-align: left;
}

.DropDown select{
    display: inline-block;
    /* width: 300px; */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid gainsboro;
    padding: 5px 2px 2px 2px;
    /* font-size: 18pt; */
    /* border: 1px solid red; */
    /* background-position: 70%; */
    padding-right: 15px;
    background-position: right center;
    background-repeat: no-repeat;
}


.DropDown .label {
    text-align: left;
    /* border: 1px solid red; */
}


.MultiSelect select
{
    width: 100%;
    height: 100%;
}


.drop-down-list {
    /* border: 1px solid green; */
}

.drop-down-list input {
    /* making it a bit darker because readonly style makes it hard to see. */
    border: 1px solid lightgray;
}

.drop-down-list div>span::after {
    /* content: url('../../../../../../../resources/images/arrow-left@3x.svg'); */
    content:"< >";
    display: inline-block;
    position:relative;
    font-size: 24px;
    float: right;
    top: -39px;
    margin-right: -4px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

/* dont allow selection of text for menu items because it looks weird */
.drop-down-list .overlay-panel {
    z-index: 2;
    position: relative;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

.drop-down-list .overlay-panel {
    display: block;
    border: 1px solid lightgray;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
}

.drop-down-list ul, .drop-down-list li {
    padding: 0;
    margin: 0;
    list-style: none;
}

.drop-down-list ul > li {
    /* background-color: yellow; */
    text-align: left;
    padding: 4px;
    margin-top: 2px;
    margin-bottom: 2px;
    cursor: pointer;
}

.drop-down-list ul > li:hover {
    background-color: lightgray;
}

/* look of selected list item */
.drop-down-list ul > li[item-selected="true"] {
    color: #FFF;
    background-color: grey;
}


.drop-down-list_inputError
{
  border: 1px solid red !important;
}
