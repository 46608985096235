.AmountInputBox {
  position: relative;
  width: 100%;
}

.AmountInput_AmountError > input
{
  border: 2px solid red !important;
  border-radius: 4px !important;
}
