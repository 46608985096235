.AddressInput input[type="text"]{
  display: inline-block;
  text-align: left;
  line-height: 48px;
  font-size: 14pt;
}

.AddressInput input.invalid{
  border: 2px solid red;
}

