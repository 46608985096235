.paymentform-footer {
    /* border: 2px solid green; */
}


.paymentform-footer {
    /* padding-left: 100px; */
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #DCDCDC;
    font-size: 12px;
    color: #7C7F84;
    /* white-space: nowrap; */
    /* clear: both; */
    text-align: center;
}

.paymentform-footer span {
    margin-right: 10%;
}

.footer-nav-link {
    padding: 10px;
}
