.TextInputBox{
  text-align: left;
  color: grey;
}

.TextInputBox input[type="text"]{
  border: 2px solid gainsboro;
  /*border-radius: 2px;*/
  padding: 0px 4px ;
  /* line-height: 50px; */
  font-size: 12pt;
  text-align: left;
  margin: 10px 0;
  width:50%;
} 


.TextInputBox_inputError
{
  border: 1px solid red !important;
}
