/* main PaymentLink application styles */
.modal-position {
  margin-top: 5%;
  margin-left: 10%;
  padding: 20px;
  height: 80%;
  overflow-y: scroll;
  overflow: -moz-scrollbars-vertical;
}

.fixed-modal {
  position: fixed;
  background-color: #FFF;
  z-index: 1;
}

::-webkit-scrollbar {
  -webkit-box-shadow: 0 0 5px rgba(255, 255, 255, .3);
  -webkit-appearance: none;
  width: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, .3);
  -webkit-box-shadow: 0 0 5px rgba(255, 255, 255, .3);
}

.box-card {
  border-width: 1px;
  color: black;
  border-style: solid;
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

input.disabled-style {
    background-color: lightgray;
    border: 1px solid gray;
}

.payment-link {
    width: 100%;
    height: 100%;
    background-color: #F7F8F9;
    color: #B2B7C3;
    /* font-family: 'Helvetica Neue'; */
}

.with-prepended-icon > input 
{
    padding-left: 20px;
}

/* note styling the date picker look at the react date picker component */
.DatePicker input,
.DropDown select,
.payment-link input[type=password],
.payment-link input[type="number"],
.payment-link input[type="text"],
.payment-link input[type="tel"],
.payment-link input[type="email"] {
    color: #555;
    height: 45px;
    /* line-height: 50px; */
    width: 100%;
    font-size: 20px;
}
.payment-link textarea {
    color: #555;
    height: 150px;
    line-height: 35px;
    width: 100%;
    font-size: 20px;
    border-style: solid;
    min-height: 100px;
    max-height: 300px;
}

.payment-link input[type='password'].passwordInputBox {
    border: 1px solid #D6D8DC;
}

.payment-link select {
    color: #555;
    font-size: 20px;
}

.loader,
.loader:before,
.loader:after {
  background: gray;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: gray;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
.surcharge-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.total-payment-box {
  flex: 1;
  text-align: left;
}
.surcharge-message {
  flex: 2;
  text-align: center;
  white-space: pre-line;
}
.surcharge-recur-ack {
  display: flex;
  justify-content: center;
}
.surcharge-recur-ack input[type='checkbox'] {
  margin-top: 5px;
  margin-right: 10px;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}