@media only screen and (max-width: 540px) {
    .review table th, .review table td {
        padding: 5px;
    }
    .review table {
        overflow-y: visible;
        padding-bottom: 10px;
        border-collapse: separate;
    }
    .review table.table-bordered{
        /* border: 1px solid black; */
    }
    .review table.table-bordered > thead > tr > th{
        border:1px solid black;
    }
    .review table.table-bordered > tbody > tr > td{
        border:1px solid black;
    }
}
