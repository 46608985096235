
.expirationDateInput {
  position: relative;
  text-align: left;
}

.expirationDateInput > input {
    /* border: 1px solid gainsboro; */
    /* border-radius: 4px; */
    /* padding: 20px 10px ; */
    /* line-height: 50px; */
    font-size: 12pt;
    text-align: left;
    /* margin: 10px 0; */
}
