.receipt {
    /* border: 1px solid green; */
}

.receipt table {
    /*width: 500px;*/ /* hack delete */
    color: gray;
}

.receipt table tr {
    height: 50px;
}

.receipt table tr>td:nth-child(1) {
    font-weight: bold;
}

.receipt table tr>td:nth-child(2) {
    text-align: right;
}
