.PaymentSteps {
    text-align: center;
    padding-top: 10%;
    padding-bottom: 10%;
    /* border: 2px solid red; */
    width: 100%;
}

.horizontalList {
    display: inline-block;
}

.PaymentSteps ul.horizontalList {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.PaymentSteps ul.horizontalList li {
  float: left;
  /* border: 1px solid red; */
  width: 100px;
}
