.review {
    /* padding-left: 30px; */ /* align it to the parent works better with smaller screen */
    /* border: 3px dotted blue; */
}

.review table td:nth-child(2){
    /* font-weight: 900; */
    /* color: #000; */
    font-weight: bold;
    color: #454545;
}

.review table td:nth-child(1){
    font-weight: bold;
    color: #999999;
}

.review table button {
    background-color: transparent;
    color: #2A9FD8;
}

.review table thead {
    background-color: #F7F8F9;
    color: #3B424E;
    border-bottom: 0px;
}

.review table th {
    border: 0px;
}

.review table th, .review table td {
    padding: 20px;
}

.review table > tbody > tr {
    background-color: gainsboro;
}

.termsAgree input[type='checkbox'] {
    margin-top: 50px;
    margin-right: 10px;
}

.termsAgree label {
    display: contents;
}

.checkbox-round {
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border-radius: 50%;
    vertical-align: -4px;
    border: 1px solid gray;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.checkbox-round:checked {
    background-color: gray;
}
