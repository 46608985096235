.image-circle-frame {
    display: inline-block;
    /* background-position: center;
    background-size: 125%; */
    background-color: white;
    position: relative;
    overflow: hidden;
    /*border-radius: 50%;
     height: 100px;
    width: 100px; */
}
