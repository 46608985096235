.Country {
    /* width:300px; */
    overflow:hidden;
}

.Country select{
    display: inline-block;
    /* width: 300px; */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid gainsboro;
    padding: 5px 2px 2px 2px;
    /* font-size: 18pt; */
    /* border: 1px solid red; */
    /* background-position: 70%; */
    background-position: right center;
    background-repeat: no-repeat;
}
