/*  move this and delete file. */
.nav-buttons {
    /* border: 2px solid red; */
    /* width: 100%; */
}

.nav-buttons .previous-button {
    /* border: 2px solid green; */
}

.nav-buttons .continue-button {
    /* border: 2px solid green; */
}

.nav-buttons .previous-button button {
    text-align: left;
}

.nav-buttons .previous-button button i {
    padding-right: 10px;
}

.nav-buttons .previous-button button span {
    vertical-align: -3px;
}

.nav-buttons button {
    height: 50px;
    min-width: 100px;
}
