#payon {
    width: 100%;
}

.DayPickerInput {
    width: 100%;
    display: inline-block;
}
.DatePicker {

}
.DatePicker span {
    /* vertical-align: -30%; */
}
.DatePicker span.calendar-icon {
    pointer-events:none;
    position: relative;
    /* background-color: red; */
    float: right;
    height: 30px;
    padding-left: 40px;
    margin-top: -37px;
}

.DatePicker img {
    /* margin-left: -20px; */
}


.DayPicker-Day--disabled {
    font-weight: lighter;
    color: rgba(0, 0, 0, 0.1);
    /* background-color: green; */
}

.DayPickerPanel {
    background-color: gainsboro;
    color: black;
    font-weight: bolder;
    border: 2px solid gray;
}

.date-input-error input{
    border: 2px solid red;
}
