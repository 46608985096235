.login-widget {
    /* border: 1px solid green; */
}

.login-widget {
    white-space: normal;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    /* width: 200px; */
    background-color: #CED4DA;
    text-align: center;
}

.login-widget .login-message > div:first-child {
    color: #3B424E;
}

.login-widget .login-link {
    color: #4696CA;
    font-size: 12px;
    margin-top: 25px;
    cursor: pointer;
}

.login-widget .login-message {
    text-align: left;
    margin-top: 20px;
    font-size: 11pt;
    color: #7C7F84;
}

.login-popup {
    /* border: 1px solid green; */
    /* using rgba on background so child element can have its own color with opacity value */
    background: rgba(0, 0, 0, 0.4);
    /* opacity: 0.7; */
    /* For IE8 and earlier */
    /* filter: alpha(opacity=70); */
    display: inline-block;
    z-index: 1;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 100%;
}

.login-popup.disabled {
    display: none;
}

.login-popup.enabled {
    display: inline-block;
}

.login-popup > div {
    border-radius: 5px;
    margin-top: 10%;
    padding: 5%;
    background-color: #FFF;
    background: rgba(255, 255, 255, 1);
    color: #000;
    text-align: left;
    width: 50%;
    margin-left: 20%;
}
