.header-label {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}

.header-label span.label {
    color: #000000;
}

.header-label img {
    margin-right: 10px;
}
