.disclaimer {
    /* border: 2px solid black; */
}

.disclaimer > div {
    height: 200px;
    width: 300px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
}
