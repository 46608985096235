html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'WorkSans-Medium', 'Helvetica Neue';
    font-size: 12px;
}


