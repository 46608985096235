.RoundCheckbox label.active {
    font-weight: 900;
    color: #000;
}

.RoundCheckbox .checkmark {
    display:inline-block;
    width: 20px;
    height: 20px;
    border-radius:50%;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}

.RoundCheckbox .checkmark {
    border: 1px solid #000;
}

.RoundCheckbox .checkmark:before{
    content:"";
    position: absolute;
    width:3px;
    height:9px;
    background-color:#fff;
    left:9px;
    top:5px;
}

.RoundCheckbox .checkmark:after{
    content:"";
    position: absolute;
    width:3px;
    height:3px;
    background-color:#fff;
    left:7px;
    top:11px;
}
